@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
    --primary-color  : #5CAC0E;
    --secondary-color: #FF9800;
    --light-color    : #FFB573;
}

* {
    padding    : 0;
    margin     : 0;
    font-family: Poppins;
}

/* Responsive  */



/* Small Mobile */

@media only screen and (min-width : 200px) {}

/* Smart Mobile  */

@media only screen and (min-width : 320px) {
    .lastSection {
        background         : url("../assets/flag.png") no-repeat;
        background-size    : auto;
        background-position: center;
        width              : 100%;
    }

    .aboutContainer {
        display        : block;
        justify-content: center;
        align-items    : center;
    }

    .aboutContainerLeft {}

    .aboutContainerLeftImage {
        width: 390px;
    }

    .aboutContainerLeftRight {
        margin-top     : 60px;
        width          : 100%;
        justify-content: center;
        align-items    : center;
        text-align     : center;
    }

    .aboutContainerRightTitle {
        color      : var(--secondary-color);
        font-size  : 25px;
        font-weight: 700;
    }

    .aboutContainerRightHeading {
        font-weight : 900;
        font-size   : 25px;
        margin-top  : 5px;
        width       : 90%;
        margin-right: 20px;
        margin-left : 20px;
    }

    .customerReview {
        font-weight : 500;
        font-size   : 18px;
        margin-top  : 15px;
        width       : 90%;
        margin-right: 20px;
        margin-left : 20px;
    }

    .customerReviewContainer {
        display        : flex;
        margin-top     : 20px;
        justify-content: center;
    }

    .customerImage {
        width        : 60px;
        border-radius: 50px;
        margin-right : 15px;
    }

    .customerMetaDataConatiner {
        margin-top: 5px;
    }

    .customerName {
        font-size  : 18px;
        font-weight: 500;
    }

    .customerPosition {
        font-size  : 16px;
        font-weight: 400;
    }

    .ratingContainer {
        margin-top : 17px;
        margin-left: 10px;
    }

    .starImage {
        width       : 160px;
        margin-right: 16px;
    }

    .ratingNumber {
        font-size  : 18px;
        font-weight: 700;
        margin-top : -10px;
    }

    .footerContainer {
        display      : inline-flex;
        margin-top   : 90px;
        padding-left : 30px;
        padding-right: 30px;
        margin-bottom: 20px;
        flex-wrap    : wrap;
    }

    .fsection1 {
        width        : 100%;
        margin-bottom: 20px;
    }

    .footerLogo {
        width        : 120px;
        margin-bottom: 20px;
    }

    .aboutContent {
        width: 80%;
    }

    .fsection2 {
        width     : 45%;
        margin-top: 20px;
    }

    .footerHeading {
        font-size  : 22px;
        font-weight: 500;
    }

    .footerNav {
        margin-top     : 23px;
        list-style-type: none
    }

    .footerNavItem {
        margin-bottom: 10px;
        cursor       : pointer;
    }

    .footerNavItem:hover {
        color: var(--primary-color);
    }

    .fsection3 {
        width       : 15%;
        margin-right: 20px;
        width       : 45%;
        margin-top  : 20px;

    }

    .fsection4 {
        width     : 45%;
        margin-top: 20px;
    }

    .fsection5 {
        width     : 50%;
        margin-top: 20px;
    }

    .newsLatterContentFirst {
        margin-top: 20px;
        font-size : 18px;
    }

    .newsLatterContentSecond {
        margin-top: 15px;
        font-size : 18px;

    }

    .newslatter {
        display        : flex;
        border         : 2px solid #BDBDBD;
        margin-top     : 20px;
        padding        : 10px;
        border-radius  : 40px;
        justify-content: space-between;
        padding-left   : 20px;
    }

    .newslatterInput {
        border          : none;
        background-color: transparent;
        font-size       : 17px;
        color           : black;
        width           : 75%;
    }

    .newslatterInput::placeholder {
        color: black;
    }

    .newslatterInput:focus {
        outline: none;
    }

    .socialNetwork {
        display   : flex;
        margin-top: 20px;
    }

    .socialIcon {
        width       : 25px;
        margin-right: 10px;
    }

    .copyrightCredit {
        text-align   : center;
        margin-bottom: 20px;
        font-size    : 15px;
    }
}

/* Tablet  */

@media only screen and (min-width : 768px) {
    .lastSection {
        background         : url("../assets/flag.png") no-repeat;
        background-size    : auto;
        background-position: center;
        width              : 100%;
    }

    .aboutContainer {
        display        : block;
        justify-content: center;
        align-items    : center;
    }

    .aboutContainerLeft {}

    .aboutContainerLeftImage {
        width: 720px;
    }

    .aboutContainerRight {
        margin-top     : 100px;
        width          : 100%;
        justify-content: center;
        align-items    : center;
        text-align     : center;
    }

    .aboutContainerRightTitle {
        color      : var(--secondary-color);
        font-size  : 22px;
        font-weight: 700;

    }

    .aboutContainerRightHeading {
        font-weight : 900;
        font-size   : 35px;
        margin-top  : 15px;
        width       : 100%;
        margin-left : 20px;
        margin-right: 20px;
    }

    .customerReview {
        font-weight: 500;
        font-size  : 18px;
        margin-top : 15px;
        width      : 100%;
    }

    .customerReviewContainer {
        display        : flex;
        margin-top     : 26px;
        justify-content: center;
    }

    .customerImage {
        width        : 60px;
        border-radius: 50px;
        margin-right : 15px;
    }

    .customerMetaDataConatiner {
        margin-top: 5px;
    }

    .customerName {
        font-size  : 18px;
        font-weight: 500;
    }

    .customerPosition {
        font-size  : 16px;
        font-weight: 400;
    }

    .ratingContainer {
        margin-top : 17px;
        margin-left: 10px;
    }

    .starImage {
        width       : 160px;
        margin-right: 16px;
    }

    .ratingNumber {
        font-size  : 18px;
        font-weight: 700;
        margin-top : -10px;
    }

    .footerContainer {
        display      : flex;
        margin-top   : 90px;
        padding-left : 30px;
        padding-right: 30px;
        margin-bottom: 20px;
        flex-wrap    : nowrap;

    }

    .fsection1 {
        width: 25%;
    }

    .footerLogo {
        width        : 120px;
        margin-bottom: 20px;
    }

    .aboutContent {
        width    : 80%;
        font-size: 23px;
    }

    .fsection2 {
        width     : 15%;
        margin-top: 0px;

    }

    .footerHeading {
        font-size  : 22px;
        font-weight: 500;
    }

    .footerNav {
        margin-top     : 23px;
        list-style-type: none
    }

    .footerNavItem {
        margin-bottom: 10px;
        cursor       : pointer;
    }

    .footerNavItem:hover {
        color: var(--primary-color);
    }

    .fsection3 {
        width       : 15%;
        margin-right: 20px;
    }

    .fsection4 {
        width     : 15%;
        margin-top: 0px;
    }

    .fsection5 {
        width     : 30%;
        margin-top: 0px;
    }

    .newsLatterContentFirst {
        margin-top: 20px;
        font-size : 18px;
    }

    .newsLatterContentSecond {
        margin-top: 15px;
        font-size : 18px;

    }

    .newslatter {
        display        : flex;
        border         : 2px solid #BDBDBD;
        margin-top     : 20px;
        padding        : 10px;
        border-radius  : 40px;
        justify-content: space-between;
        padding-left   : 20px;
    }

    .newslatterInput {
        border          : none;
        background-color: transparent;
        font-size       : 17px;
        color           : black;
        width           : 75%;
    }

    .newslatterInput::placeholder {
        color: black;
    }

    .newslatterInput:focus {
        outline: none;
    }

    .socialNetwork {
        display   : flex;
        margin-top: 20px;
    }

    .socialIcon {
        width       : 25px;
        margin-right: 30px;
    }

    .copyrightCredit {
        text-align   : center;
        margin-bottom: 20px;
        font-size    : 15px;
    }
}


/* Laptop  */

@media only screen and (min-width : 1200px) {
    .lastSection {
        background     : url("../assets/flag.png") no-repeat;
        background-size: cover;
        width          : 100%;
    }

    .aboutContainer {
        display        : flex;
        justify-content: space-between;

    }

    .aboutContainerLeft {}

    .aboutContainerLeftImage {
        width      : 276px;
        margin-left: 78px;
        margin-top : 150px;

    }

    .aboutContainerLeftRight {
        margin-top     : 190px;
        width          : 50%;
        justify-content: left;
        align-items    : left;
        text-align     : left;
    }

    .aboutContainerRightTitle {
        color      : var(--secondary-color);
        font-size  : 22px;
        font-weight: 700;
        margin-left: 20px;
    }

    .aboutContainerLeftRightHeading {
        font-weight: 900;
        font-size  : 35px;
        margin-top : 15px;
    }

    .customerReview {
        font-weight: 500;
        font-size  : 18px;
        margin-top : 15px;
        width      : 80%;
    }

    .customerReviewContainer {
        display        : flex;
        margin-top     : 26px;
        justify-content: left;

    }

    .customerImage {
        width        : 60px;
        border-radius: 50px;
        margin-right : 15px;
    }

    .customerMetaDataConatiner {
        margin-top: 5px;
    }

    .customerName {
        font-size  : 18px;
        font-weight: 500;
    }

    .customerPosition {
        font-size  : 16px;
        font-weight: 400;
    }

    .ratingContainer {
        margin-top : 24px;
        margin-left: 0px;

    }

    .starImage {
        width       : 160px;
        margin-right: 16px;
    }

    .ratingNumber {
        font-size  : 18px;
        font-weight: 700;
        margin-top : -10px;
    }

    .footerContainer {
        display      : flex;
        margin-top   : 90px;
        padding-left : 60px;
        padding-right: 60px;
        margin-bottom: 20px;
        flex-wrap    : nowrap;

    }

    .fsection1 {
        width: 50%;
    }

    .footerLogo {
        width        : 250px;
        margin-bottom: 20px;
    }

    .aboutContent {
        width    : 80%;
        font-size: 23px;
    }

    .fsection2 {
        width     : 30%;
        margin-top: 0px;

    }

    .footerHeading {
        font-size  : 22px;
        font-weight: 500;
    }

    .footerNav {
        margin-top     : 23px;
        list-style-type: none
    }

    .footerNavItem {
        margin-bottom: 10px;
        cursor       : pointer;
    }

    .footerNavItem:hover {
        color: var(--primary-color);
    }

    .fsection3 {
        width: 15%;
    }

    .fsection4 {
        width     : 15%;
        margin-top: 0px;
    }

    .fsection5 {
        width     : 20%;
        margin-top: 0px;
    }

    .newsLatterContentFirst {
        margin-top: 20px;
        font-size : 18px;
    }

    .newsLatterContentSecond {
        margin-top: 15px;
        font-size : 18px;

    }

    .newslatter {
        display        : flex;
        border         : 2px solid #BDBDBD;
        margin-top     : 20px;
        padding        : 10px;
        border-radius  : 40px;
        justify-content: space-between;
        padding-left   : 20px;
        width          : 210px;
    }

    .newslatterInput {
        border          : none;
        background-color: transparent;
        font-size       : 17px;
        color           : black;
        width           : 75%;
    }

    .newslatterInput::placeholder {
        color: black;
    }

    .newslatterInput:focus {
        outline: none;
    }

    .socialNetwork {
        display   : flex;
        margin-top: 9px;
    }

    .socialIcon {
        width       : 35px;
        margin-right: 10px;
    }

    .copyrightCredit {
        text-align   : center;
        margin-bottom: 20px;
        font-size    : 15px;
    }

    .emailIcon {
        width: 30px;
    }
}

/* Desktop  */

@media only screen and (min-width : 1600px) {
    .lastSection {
        background     : url("../assets/flag.png") no-repeat;
        background-size: cover;
        width          : 100%;
    }

    .aboutContainer {
        display        : flex;
        justify-content: space-between;

    }

    .aboutContainerLeft {}

    .aboutContainerLeftImage {
        width      : 276px;
        margin-left: 78px;
        margin-top : 150px;

    }

    .aboutContainerLeftRight {
        margin-top     : 190px;
        width          : 50%;
        justify-content: left;
        align-items    : left;
        text-align     : left;
    }

    .aboutContainerRightTitle {
        color      : var(--secondary-color);
        font-size  : 22px;
        font-weight: 700;
        margin-left: 20px;
    }

    .aboutContainerLeftRightHeading {
        font-weight: 900;
        font-size  : 35px;
        margin-top : 15px;
    }

    .customerReview {
        font-weight: 500;
        font-size  : 18px;
        margin-top : 15px;
        width      : 80%;
    }

    .customerReviewContainer {
        display        : flex;
        margin-top     : 26px;
        justify-content: left;

    }

    .customerImage {
        width        : 60px;
        border-radius: 50px;
        margin-right : 15px;
    }

    .customerMetaDataConatiner {
        margin-top: 5px;
    }

    .customerName {
        font-size  : 18px;
        font-weight: 500;
    }

    .customerPosition {
        font-size  : 16px;
        font-weight: 400;
    }

    .ratingContainer {
        margin-top : 24px;
        margin-left: 0px;

    }

    .starImage {
        width       : 160px;
        margin-right: 16px;
    }

    .ratingNumber {
        font-size  : 18px;
        font-weight: 700;
        margin-top : -10px;
    }

    .footerContainer {
        display      : flex;
        margin-top   : 90px;
        padding-left : 60px;
        padding-right: 60px;
        margin-bottom: 20px;
        flex-wrap    : nowrap;

    }

    .fsection1 {
        width: 50%;
    }

    .footerLogo {
        width        : 250px;
        margin-bottom: 20px;
    }

    .aboutContent {
        width    : 80%;
        font-size: 23px;
    }

    .fsection2 {
        width     : 30%;
        margin-top: 0px;

    }

    .footerHeading {
        font-size  : 22px;
        font-weight: 500;
    }

    .footerNav {
        margin-top     : 23px;
        list-style-type: none
    }

    .footerNavItem {
        margin-bottom: 10px;
        cursor       : pointer;
    }

    .footerNavItem:hover {
        color: var(--primary-color);
    }

    .fsection3 {
        width: 15%;
    }

    .fsection4 {
        width     : 15%;
        margin-top: 0px;
    }

    .fsection5 {
        width     : 20%;
        margin-top: 0px;
    }

    .newsLatterContentFirst {
        margin-top: 20px;
        font-size : 18px;
    }

    .newsLatterContentSecond {
        margin-top: 15px;
        font-size : 18px;

    }

    .newslatter {
        display        : flex;
        border         : 2px solid #BDBDBD;
        margin-top     : 20px;
        padding        : 10px;
        border-radius  : 40px;
        justify-content: space-between;
        padding-left   : 20px;
        width          : 210px;
    }

    .newslatterInput {
        border          : none;
        background-color: transparent;
        font-size       : 17px;
        color           : black;
        width           : 75%;
    }

    .newslatterInput::placeholder {
        color: black;
    }

    .newslatterInput:focus {
        outline: none;
    }

    .socialNetwork {
        display   : flex;
        margin-top: 9px;
    }

    .socialIcon {
        width       : 35px;
        margin-right: 10px;
    }

    .copyrightCredit {
        text-align   : center;
        margin-bottom: 20px;
        font-size    : 15px;
    }

    .emailIcon {
        width: 30px;
    }
}