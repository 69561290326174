@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
    --primary-color  : #5CAC0E;
    --secondary-color: #FF9800;
    --light-color    : #FFB573;
}

* {
    padding    : 0;
    margin     : 0;
    font-family: Poppins;
}

/* Responsive  */



/* Small Mobile */

@media only screen and (min-width : 200px) {}

/* Smart Mobile  */

@media only screen and (min-width : 320px) {
    .popupContainer {
        display: none;
    }
}

/* Tablet  */

@media only screen and (min-width : 768px) {
    .popupContainer {
        display: none;
    }
}


/* Laptop  */

@media only screen and (min-width : 1200px) {
    .popupContainer {
        position       : absolute;
        z-index        : 999;
        top            : 5px;
        overflow-y     : hidden;
        right          : 1px;
        display        : flex;
        justify-content: center;
        width          : 90%;
        height         : 212px;
    }

    .menuImageContainer {
        width: 45%;
    }

    .menuContentContainer {
        width: 45%;
    }
    .popUpBg {
        position: absolute;
        z-index : -1;
        width   : 100%;
        height  : 100%;
    }

    .menuImage {
        width        : 120px;
        margin-top   : 20px;
        margin-left  : 10px;
        border-radius: 10px;
    }

    .menuName {
        color      : black;
        font-size  : 14px;
        font-weight: 600;
        width      : 90%;
        margin-top : 16px;
        text-align : left;
        line-height: 25px;

    }

    .menuContent {
        color      : black;
        font-size  : 12px;
        font-weight: 400;
        width      : 90%;
        text-align : left;
    }

    .menusPopupCardPriceContainer {
        text-align: left;
    }

    .menusPopupCardPriceDollar {
        color       : #F2C94C;
        font-size   : 14px;
        margin-right: 3px;
    }

    .menusPopupCardPrice {
        color      : #000;
        font-weight: 700;
    }

    .menusPopupCardPrice {
        color: #000;
    }

    .popupBtnContainer {
        text-align: left;
        margin-top: 5px;
    }

    .popupCta {
        border-radius   : 50px;
        background-color: var(--primary-color);
        border          : none;
        color           : #fff;
        cursor          : pointer;
        font-size       : 11px;
        margin-bottom   : 20px;
        width           : 90px;
        height          : 28px;
    }

}


/* Desktop  */

@media only screen and (min-width : 1600px) {
    .popupContainer {
        position       : absolute;
        z-index        : 999;
        top            : 5px;
        overflow-y     : hidden;
        right          : 1px;
        display        : flex;
        justify-content: center;
        width          :75%;
        height         : 212px;
    }

    .menuImageContainer {
        width: 45%;
    }

    .menuContentContainer {
        width: 45%;
    }
    .popUpBg {
        position: absolute;
        z-index : -1;
        width   : 100%;
        height  : 100%;
    }

    .menuImage {
        width        : 120px;
        margin-top   : 20px;
        margin-left  : 10px;
        border-radius: 10px;
    }

    .menuName {
        color      : black;
        font-size  : 14px;
        font-weight: 600;
        width      : 90%;
        margin-top : 16px;
        text-align : left;
        line-height: 25px;

    }

    .menuContent {
        color      : black;
        font-size  : 12px;
        font-weight: 400;
        width      : 90%;
        text-align : left;
    }

    .menusPopupCardPriceContainer {
        text-align: left;
    }

    .menusPopupCardPriceDollar {
        color       : #F2C94C;
        font-size   : 14px;
        margin-right: 3px;
    }

    .menusPopupCardPrice {
        color      : #000;
        font-weight: 700;
    }

    .menusPopupCardPrice {
        color: #000;
    }

    .popupBtnContainer {
        text-align: left;
        margin-top: 5px;
    }

    .popupCta {
        border-radius   : 50px;
        background-color: var(--primary-color);
        border          : none;
        color           : #fff;
        cursor          : pointer;
        font-size       : 11px;
        margin-bottom   : 20px;
        width           : 90px;
        height          : 28px;
    }
}