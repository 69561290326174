@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
    --primary-color: #5CAC0E;
}

* {
    padding: 0;
    margin : 0;
}

/* Responsive  */



/* Small Mobile */

@media only screen and (min-width : 200px) {
    .headerContainer {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 10px;
        padding-bottom : 10px;
    }

    .logoContainer {
        margin-left: 40px;
    }

    .logo {
        width : 131px;
        height: 57px;
        cursor: pointer;
    }

    .menuContainer {
        margin-right: 20px;
    }

    .menu {
        display        : flex;
        list-style-type: none;
    }

    .menuShow {
        display         : block;
        position        : absolute;
        z-index         : 99999;
        top             : 80px;
        left            : 10px;
        right           : 20px;
        background-color: #fff;
        width           : 88;
        padding-left    : 20px;
        box-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition      : 0.3s;
    }

    .menuHide {
        display: none;
    }

    .menuItem {
        margin-right : 40px;
        font-size    : 14px;
        font-weight  : 500;
        font-family  : Poppins;
        margin-bottom: 10px;
    }

    .menuItem:hover {
        color : var(--primary-color);
        cursor: pointer;
    }

    .menuToggle {
        display: block;
    }

    .menuToggleLine {
        width           : 20px;
        height          : 4px;
        background-color: var(--primary-color);
        margin-bottom   : 5px;
        border-radius   : 20px;
    }
}

/* Smart Mobile  */

@media only screen and (min-width : 320px) {
    .headerContainer {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 15px;
        padding-bottom : 15px;
    }

    .logoContainer {
        margin-left: 40px;
    }

    .logo {
        width : 131px;
        height: 57px;
        cursor: pointer;
    }

    .menuContainer {
        margin-right: 20px;
    }

    .menu {
        display        : flex;
        list-style-type: none;
    }

    .menuShow {
        display         : block;
        position        : absolute;
        top             : 80px;
        left            : 10px;
        right           : 20px;
        background-color: #fff;
        width           : 88;
        padding-left    : 20px;
        box-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition      : 0.3s;
    }

    .menuHide {
        display: none;
    }

    .menuItem {
        margin-right : 40px;
        font-size    : 14px;
        font-weight  : 500;
        font-family  : Poppins;
        margin-bottom: 10px;
    }

    .menuItem:hover {
        color : var(--primary-color);
        cursor: pointer;
    }

    .menuToggle {
        display: block;
    }

    .menuToggleLine {
        width           : 20px;
        height          : 4px;
        background-color: var(--primary-color);
        margin-bottom   : 5px;
        border-radius   : 20px;
    }
}

/* Tablet  */

@media only screen and (min-width : 768px) {
    .headerContainer {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 10px;
        padding-bottom : 10px;
    }

    .logoContainer {
        margin-left: 40px;
    }

    .logo {
        width : 131px;
        height: 57px;
        cursor: pointer;
    }

    .menuContainer {
        margin-right: 0px;
    }

    .menu {
        display        : flex;
        list-style-type: none;
    }

    .menuItem {
        margin-right: 40px;
        font-size   : 17px;
        font-weight : 500;
        font-family : Poppins;
    }

    .menuItem:hover {
        color : var(--primary-color);
        cursor: pointer;
    }

    .menuToggle {
        display: none;
    }
}

/* Laptop  */

@media only screen and (min-width : 1200px) {
    .headerContainer {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 10px;
        padding-bottom : 10px;
    }

    .logoContainer {
        margin-left: 40px;
    }

    .logo {
        width : 131px;
        height: 57px;
        cursor: pointer;
    }

    .menuContainer {
        margin-right: 20px;
    }

    .menu {
        display        : flex;
        list-style-type: none;
    }

    .menuItem {
        margin-right: 40px;
        font-size   : 17px;
        font-weight : 500;
        font-family : Poppins;
    }

    .menuItem:hover {
        color : var(--primary-color);
        cursor: pointer;
    }

    .menuToggle {
        display: none;
    }
}

/* Desktop  */

@media only screen and (min-width : 1600px) {
    .headerContainer {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding-top    : 10px;
        padding-bottom : 10px;
    }

    .logoContainer {
        margin-left: 40px;
    }

    .logo {
        width : 131px;
        height: 57px;
        cursor: pointer;
    }

    .menuContainer {
        margin-right: 20px;
    }

    .menu {
        display        : flex;
        list-style-type: none;
    }

    .menuItem {
        margin-right: 40px;
        font-size   : 17px;
        font-weight : 500;
        font-family : Poppins;
    }

    .menuItem:hover {
        color : var(--primary-color);
        cursor: pointer;
    }

    .menuToggle {
        display: none;
    }
}